<template>
  <div id="app" :style="appStyle">
    <div class="main-header-wrapper">
      <Header />
    </div>
    <div class="main-body-wrapper">
      <!-- <transition name="router-fade" mode="out-in">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition> -->
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
    <div class="main-footer-wrapper" v-if="showToolBar">
      <Footer />
    </div>
    <div class="loading-main-wrapper" v-if="$store.getters.loading">
      <Loading />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Loading from '@/components/Loading'
export default {
  name: 'app',
  components: {
    Header,
    Footer,
    Loading,
  },
  data() {
    return {
      showToolBar: false,
      appStyle: {
        paddingBottom: '3rem',
      },
    }
  },
  watch: {
    $route(to) {
      if (to.meta.toolBar) {
        this.showToolBar = true
        this.appStyle.paddingBottom = '3rem'
      } else {
        this.showToolBar = false
        this.appStyle.paddingBottom = '0'
      }
    },
  },
}
</script>

<style lang="scss">
@import './style/common';
@import './style/mixin';
#app {
  @include wh(100%, 100%);
  box-sizing: border-box;
  padding-top: 0rem;
  position: relative;
  overflow: hidden;
  .main-header-wrapper {
    @include wh(100%, 3rem);
    position: absolute;
    left: 0;
    top: 0;
    display: none;
  }
  .main-body-wrapper {
    @include wh(100%, 100%);
    overflow-y: auto;
  }
  .main-footer-wrapper {
    @include wh(100%, 3rem);
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.router-fade-enter-active,
.router-fade-leave-active {
  transition: opacity 0.3s;
}
.router-fade-enter,
.router-fade-leave-active {
  opacity: 0;
}
</style>
